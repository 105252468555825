  
  .service-section-img-1 {
    background-image: url('C:\Users\tiena\OneDrive\Bureau\Rhema\rhema\src\components\background-services-img_1.jpg');
    background-size: cover;
    padding: 20px;
    margin-bottom: 20px;
    margin-top: 80px;
    scroll-margin-top: 200px;
   
  }

  .service-section-img-2 {
    background-image: url('C:\Users\tiena\OneDrive\Bureau\Rhema\rhema\src\components\background-services-img_2.jpg');
    background-size: cover;
    padding: 20px;
    margin-bottom: 20px;
    margin-top: 80px;
    scroll-margin-top: 200px;
   
  }

  

  .service-section-img-1 h2
  .service-section-img-2 h2 {
    text-align: left;
    font-size: 30px;
    margin-bottom: 40px;
  }

  .service-section-img-1 p {
    text-align: left;
    font-size: 20px;
    line-height: 150%;
    color: rgb(12, 12, 89);
    padding: 0;
    font-weight: 580;
  }

  .service-section-img-2 p {
    text-align: left;
    font-size: 20px;
    line-height: 150%;
    color: rgb(12, 12, 89);
    padding: 0;
    font-weight: 580;
  }


  .service-section {
    margin-bottom: 20px;
    margin-top: 80px;
    scroll-margin-top: 200px;
  }
  
 
  
  .service-section p {
    text-align: left;
    font-size: 20px;
    line-height: 150%;
    padding: 0;
  }
  
  hr {
    height: 10px;
    border: 0;
    box-shadow: 0 10px 10px -10px #3a60df inset;
  }
  
  h2 {
    text-align: left;
    font-size: 30px;
    margin-bottom: 40px;
    color: rgb(12, 12, 89);
    text-shadow: 0px 0px 5px rgb(181, 220, 189), 0px 0px 10px rgb(181, 220, 189), 0px 0px 10px rgb(181, 220, 189),
      0px 0px 20px rgb(181, 220, 189);
  }
  
.footer {
    background-color: #f2f2f2;
    padding: 20px;
    margin-top: 100px;
  }
  
  .footer__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .footer__logo {
    width: 20px;
    height: auto;
  }
  
  .footer__links ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .footer__links li {
    margin-right: 20px;
  }
  
  .footer__links a {
    color: #333;
    text-decoration: none;
  }
  
  .footer__copy {
    margin-top: 20px;
    text-align: center;
  }
  
  /* Responsive Styles */
  @media screen and (max-width: 768px) {
    .footer__content {
      flex-direction: column;
    }
  
    .footer__links ul {
      justify-content: flex-start;
    }
  
    .footer__links li {
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
  
body {

  font-family: "Roboto Condensed", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
  margin-left: 10em;
  margin-right: 10em;
  margin-bottom: 1em;


     
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

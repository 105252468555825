.contactez-nous-contact-info {
    background-image: url('C:\Users\tiena\OneDrive\Bureau\Rhema\rhema\src\components\background-contact-info.jpg');
    text-align: center;
    background-size:cover;
    padding: 20px;
}

.contactez-nous-contact-info p {
    
    font-size: 18px;
    font-weight: 600;
    
}
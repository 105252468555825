.navbar {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    
  }
  
  .navbar__menu {
  
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    flex-grow: 1;
    & > li > a {
        text-decoration: none; 
        font-weight: bold;
        font-size: large;
        font-size: 20px;
      }
    
    
  }
  
  .navbar__logo {
    width: 100px;
    height: auto; 
    margin-right: 10px;
    
    
  }
  
  .navbar__item {
    margin-right: 10px;
    position: relative;
    cursor: pointer;
    flex-grow: 1;
    font-size: 19px;
   
  }
  
  
  .navbar__submenu {
    display: none;
    position: absolute;
    background-color: white;
    min-width: 170px;
    color: blue;
    text-align: left;
    box-shadow: 0px 8px 16px 0px rgba(196, 31, 31, 0.2);
    z-index: 1;
  
  }
   
  
  .navbar__submenu-item {
    padding: 12px 16px;
    display: block;
    & > a {
        text-decoration: none;
        font-weight: normal;
      }
    
  }

  
  
  /* Responsive Styles */
  @media screen and (max-width: 768px) {
    .navbar {
      flex-direction: column;
      align-items: flex-start;
    }
    
    .navbar__menu {
      flex-direction: column;
      align-items: flex-start;
    }
    
    .navbar__item {
      margin-bottom: 10px;
    }
    
    .navbar__submenu {
      position: static;
      background-color: transparent;
      padding: 0;
    }
  }
  
  
  
  .navbar__submenu-item {
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .navbar__item:hover .navbar__submenu {
    display: block;
  }
  
  .navbar__submenu-item:hover {
    background-color: #ddd;
  }
  
  .navbar__submenu-item:hover a {
    color: #000;
  }
  
  
header {
    display: flex;
    width: 100%;
    background-color: #f2f2f2;
    justify-content: center;
    font-weight: bold;


  }
  
  .header-container {
    display: flex;
    justify-content: center;
    justify-content: space-between;
  }
  
  .contact-info {
    display: flex;
    flex-direction: row;
    align-self: flex-start;
  }
  
  .social-media {
    display: flex;
    flex-direction: row;
  }
  
    a.facebook {
    color: #4968ad;
  }
  
  a.twitter {
    color: #49a1eb;
  }
  
  a.instagram {
    color: black;
  }

   
  a.language-french,
  a.language-english {
    font-weight: bold;
    text-decoration: none;
    color: #00415a;
  }
  
  a.language-french:hover,
  a.language-english:hover {
    color: red;
  }
  
  a.language-french:active,
  a.language-english:active {
    color: blue;
  }
  
 
  ul {
    list-style-type: none;
    padding: 0;
    display: flex;
  }
  
  li {
    margin-right: 20px;
  }

.contact-info,
.social-media,
.language-choice {
  margin-right: 100px;
}
  
  /* Responsive Styles */
  @media screen and (max-width: 768px) {
    .header-container {
      flex-direction: column;
      align-items: center;
    }
  
    .contact-info,
    .social-media,
    .language-choice {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
  
.App {
  text-align: center;
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}



/*my code testimonies*/

.testimonial-slide {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  word-wrap: break-word;
}



.app-container {
  position: relative;
  overflow: auto;
  height: 100vh;
}

.header-container {
  position: sticky;
  top: 1em;
  background-color: #ffffff;
  z-index: 2;
}

.navbar {
  position: sticky;
  top: 50px;
  background-color: #e1f1fd;
  z-index: 1;
}


/* Responsive Styles */
@media screen and (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .navbar__menu {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .navbar__item {
    margin-bottom: 10px;
  }
  
  .navbar__submenu {
    position: static;
    background-color: transparent;
    padding: 0;
  }
}



.navbar__submenu-item {
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.navbar__item:hover .navbar__submenu {
  display: block;
}

.navbar__submenu-item:hover {
  background-color: #ddd;
}

.navbar__submenu-item:hover a {
  color: #000;
}



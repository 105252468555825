  
  .about-us h2 {
    text-align: left;
    font-size: 30px;
    margin-bottom: 40px;
    font-size: 30px;
    margin-bottom: 40px;
    color: rgb(12, 12, 89);
    text-shadow: 0px 0px 5px rgb(181, 220, 189), 0px 0px 10px rgb(181, 220, 189), 0px 0px 10px rgb(181, 220, 189),
      0px 0px 20px rgb(181, 220, 189);
  }
  
  .about-us p {
    text-align: left;
    font-size: 20px;
    line-height: 150%;
    color: rgb(12, 12, 89);
    padding: 0;
    font-weight: 580;
  }

  
  hr {
    height: 10px;
    border: 0;
    box-shadow: 0 10px 10px -10px #3a60df inset;
  }

h3 {
    color: red;
}

  .about-us-profil {
    background-color: #ffffff;
    padding: 20px;
    scroll-margin-top: 200px;
  }

  .about-us-mission-et-vision {
    background-color:  #ffffff;
    padding: 20px;
    scroll-margin-top: 200px;
  }

  .about-us-notre-equipe {
    background-color: #ffffff;
    padding: 20px;
    scroll-margin-top: 200px;
  }

  .about-us-clientele {
    background-color: #e1f1fd;
    color: red;
    padding: 20px;
    scroll-margin-top: 200px;
  }

  .about-us-clientele p {
    
    color: red;
    
  }

  

  
  